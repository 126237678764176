import {checkUserAuth, logInSite} from '@brightlive/shared/helpers/auth';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import {setAuthToken, updateCurrentUser} from 'redux/auth/actions';
import Api from 'library/api';
import {NextPageContext} from 'next';
import {AnyAction, Dispatch} from 'redux';
import router from 'next/router';
import * as Sentry from '@sentry/nextjs';

let unsubscribe: firebase.Unsubscribe | null = null;

/**
 * Verify Auth Token
 *
 * @param   {string}  idToken  auth token
 *
 */
export const verifyToken = async idToken => {
  const LOGIN_URL = process.env.NEXT_PUBLIC_CF_LOGIN_URL;
  const payload = {
    idToken,
  };

  const isAuthenticated = await axios({
    method: 'post',
    url: LOGIN_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
    },
    data: JSON.stringify(payload),
    withCredentials: true,
  })
    .then(res => {
      return res.data;
    }) // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .catch(err => {
      return 'unauthorized';
    });
  return isAuthenticated;
};

/**
 * Check to see if the current user is logged in
 *
 * @param   {NextPageContext | null}  ctx  Next page context used to parse cookies from the request on the server side required for server site requests only
 * @param   {boolean}  redirect  Whether to redirect to the root of the site if the user is not logged in
 *
 * @return  {Promise<firebase.User | null>}  User credential or null if the user isn't logged in
 */
export const checkAuth = async (
  dispatch: Dispatch<AnyAction> | null,
  ctx: NextPageContext | null = null,
  redirect = false
): Promise<firebase.User | null> => {
  const currentUser = await checkUserAuth(firebase.auth(), ctx);
  if (!currentUser) {
    if (redirect) {
      ctx?.res?.writeHead(302, {
        Location:
          '/?signin=true&continueUrl=' +
          encodeURIComponent(ctx?.req?.url || ''),
      });
      ctx?.res?.end();
    }
    return null;
  }
  // Signed in
  const idToken = await currentUser?.getIdToken();
  // set Bearer token here
  ctx?.store.dispatch(setAuthToken(idToken));
  const userData = await Api.fetchCurrentUser(idToken, true);
  if (typeof window !== 'undefined') {
    window?.dataLayer?.push({
      uid: userData.uid,
      company: userData.company?.uid,
    });
  }
  Sentry.setUser({id: userData.uid, email: userData.email});
  if (
    userData?.roles?.includes('creator') ||
    userData?.roles?.includes('admin') ||
    userData?.managerFor?.length > 0
  ) {
    if (dispatch) {
      // update app store with authorized user
      await dispatch(updateCurrentUser(userData));
    }
  } else {
    ctx?.res?.writeHead(302, {Location: '/'});
    ctx?.res?.end();
  }
  return currentUser;
};

/**
 * Refresh the user's auth on the client side if their session cookie is expired
 *
 * @param   {firebase.auth.Auth}  firebaseAuth  Firebase auth reference for the current site
 */
export const refreshAuth = async (
  dispatch: Dispatch<AnyAction>,
  currentUser: firebase.User | undefined,
  continueUrl = ''
) => {
  if (!currentUser) {
    if (unsubscribe) {
      unsubscribe();
    }
    unsubscribe = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const currentUser = await checkAuth(dispatch);
        window?.dataLayer?.push({uid: user?.uid});
        if (continueUrl && currentUser && typeof window !== 'undefined') {
          router.push(continueUrl);
        }
      }
    });
  } else {
    // We have a server side auth context but not a client side one due to single sign on, refresh the user's auth on the client side
    if (!firebase.auth().currentUser) {
      await checkAuth(dispatch);
    }
    window?.dataLayer?.push({uid: currentUser?.uid});
  }

  if (currentUser) {
    logInSite();
  }
};
